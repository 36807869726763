import React from 'react';

export default props => {
  return (
    <div className="row">
      <div className="col-12">
        <h1 className="mb-4">{props.children}</h1>
      </div>
    </div>
  );
};
