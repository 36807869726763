import React, { useState, useEffect } from 'react';

const Modal = props => {
  const [modal, setModal] = useState({
    backdrop: false,
    style: { display: 'none' },
    open: false,
  });
  const { open, data, className } = props;
  useEffect(() => {
    let timer = setTimeout(() => {
      if (open) setModal({ backdrop: true, style: { display: 'block' }, open });
      if (!open) setModal({ backdrop: false, style: { display: 'none' }, open });
    }, 100);

    return () => {
      clearTimeout(timer);
    };
  }, [props.open, modal.open]);
  const classShow = open ? ' show' : '';

  const closeModal = e => {
    props.onClose(false);
  };

  return (
    <>
      <div
        className={`modal fade${classShow} ${className}`}
        style={modal.style}
        id="modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="modalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            {data.header && <div className="modal-header">{data.header}</div>}
            <div className="modal-body">{data.body}</div>
            {data.footer && <div className="modal-footer">{data.footer}</div>}
          </div>
        </div>
        {modal.backdrop && <div className={`modal-backdrop fade${classShow}`} onClick={closeModal} />}
      </div>
    </>
  );
};

Modal.defaultProps = {
  data: {},
  className: '',
};

export default Modal;
