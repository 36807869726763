import querySting from 'query-string';

export const breadcrumbLists = searchPattern => {
  const parsed = querySting.parse(searchPattern);
  let lists = [];

  lists.push({ category: { id: parsed.category, name: parsed.category_name } });
  if (parsed['folder[]']) {
    let folders = [];
    if (typeof parsed['folder[]'] === 'object') {
      parsed['folder[]'].map((a, i) => {
        folders.push({
          order: i,
          id: a,
          name: parsed['folder_name[]'][i],
        });
      });
    } else {
      folders.push({
        id: parsed['folder[]'],
        name: parsed['folder_name[]'],
      });
    }

    lists.push({ folders });
  }

  if (parsed.files) {
    lists.push({ files: { id: parsed.files, name: parsed.name } });
  }

  return lists;
};

export const mobileBreadcrumbList = (searchPattern, pathname) => {
  const parsed = querySting.parse(searchPattern);
  let lists = {};
  let i = 0;

  if (parsed['folder[]']) {
    if (typeof parsed['folder[]'] === 'object') {
      const folderPath = parsed['folder[]'].reduce((a, b) => {
        let n = a;
        if (i <= parsed['folder[]'].length - 2) {
          n = a + '&folder[]=' + b + '&folder_name[]=' + parsed['folder_name[]'][i];
        }
        i++;
        return n;
      }, '');

      lists = {
        link: pathname + '?category=' + parsed.category + '&category_name=' + parsed.category_name + folderPath,
        name: parsed['folder_name[]'][parsed['folder_name[]'].length - 2],
      };
    } else {
      lists = {
        link: pathname + '?category=' + parsed.category + '&category_name=' + parsed.category_name,
        name: parsed.category_name,
      };
    }
  }

  if (!parsed['folder[]']) {
    lists = {
      link: '/',
      name: 'Home',
    };
  }

  return lists;
};

export default {
  breadcrumbLists,
  mobileBreadcrumbList,
};
