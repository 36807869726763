import React, { useState, useReducer, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';

import { Title } from '../../components';
import { Input, Button, Checkbox, Loader } from '../../ui';
import { validation } from '../../utilities';
import { formReducer as reducer, ONCHANGE, ONVALIDATE, SETTOAST } from '../../reducer/forms';

import { auth } from '../../services';
import ThankYou from './ThankYou';

const initFormErrors = {
  status: false,
  message: '',
};

const initForms = {
  forms: {
    first_name: {
      value: '',
      errors: initFormErrors,
      label: 'First Name',
      type: 'text',
      col: 'col-md-6',
    },
    last_name: {
      value: '',
      errors: initFormErrors,
      label: 'Last Name',
      type: 'text',
      col: 'col-md-6',
    },
    email: {
      value: '',
      errors: initFormErrors,
      label: 'Email Address',
      type: 'email',
      col: 'col-md-6',
    },
    sales_rep_name: {
      value: '',
      errors: initFormErrors,
      label: 'Sales Rep Name',
      type: 'text',
      col: 'col-md-6',
    },
    company: {
      value: '',
      errors: initFormErrors,
      label: 'Company Name',
      type: 'text',
      col: 'col-md-6',
    },
    account_number: {
      value: '',
      errors: initFormErrors,
      label: 'Account Number',
      type: 'text',
      col: 'col-md-6',
    },
    city: {
      value: '',
      errors: initFormErrors,
      label: 'City',
      type: 'text',
      col: 'col-md-6',
    },
    state: {
      value: '',
      errors: initFormErrors,
      label: 'State',
      type: 'text',
      col: 'col-md-6',
    },
    zip_code: {
      value: '',
      errors: initFormErrors,
      label: 'Zip Code',
      type: 'text',
      col: 'col-md-6',
    },
    password: {
      value: '',
      errors: initFormErrors,
      label: 'Password',
      type: 'password',
      col: 'col-md-6',
    },
    confirm_password: {
      value: '',
      errors: initFormErrors,
      label: 'Confirm Password',
      type: 'password',
      col: 'col-md-6',
    },
    terms: {
      value: '',
      errors: initFormErrors,
      label: 'I have read, understood, and agreed to the', // <Link to="/terms">Terms of Use.</Link>
      type: 'checkbox',
    },
  },
};

const formSchema = {
  first_name: {
    type: 'required',
    title: 'first name',
  },
  last_name: {
    type: 'required',
    title: 'last name',
  },
  email: {
    type: 'required|email',
  },
  sales_rep_name: {
    type: 'required',
    title: 'sales rep name',
  },
  company: {
    type: 'required',
    title: 'company name',
  },
  account_number: {
    type: 'required',
    title: 'account number',
  },
  city: {
    type: 'required',
  },
  state: {
    type: 'required',
  },
  zip_code: {
    type: 'required',
    title: 'zip code',
  },
  password: {
    type: 'required',
    min: 6,
  },
  confirm_password: {
    type: 'confirm',
    confirm: 'password',
    title: 'Password',
  },
  terms: {
    type: 'required',
    title: 'Terms',
    message: 'Please confirm to our terms of use',
  },
};

const initState = {
  ...initForms,
  toast: {
    status: '',
    message: '',
  },
};

const initPage = {
  title: 'Register',
  loading: true,
  data: '',
  formLoading: false,
  thankYou: false,
};

export default (props) => {
  const [state, dispatch] = useReducer(reducer, initState);
  const [page, setPage] = useState(initPage);
  const recaptchaRef = useRef(null);

  useEffect(() => {
    setPage({
      ...page,
      loading: false,
    });
  }, [page.data]);

  const handleInputChange = ({ currentTarget: input }) => {
    let inputValue = input.value;

    if (input.type === 'checkbox') {
      inputValue = input.checked ? inputValue : '';
    }

    dispatch({
      type: ONCHANGE,
      payload: {
        name: input.name,
        value: inputValue,
        errors: initFormErrors,
      },
    });
  };

  const doValidation = () => {
    const validate = validation(formSchema, state.forms);
    let fieldErrors = {};

    if (validate.length > 0) {
      validate.map((v) => {
        fieldErrors = {
          ...fieldErrors,
          [v.field]: {
            ...state.forms[v.field],
            errors: {
              status: true,
              message: v.message,
            },
          },
        };
      });

      dispatch({
        type: ONVALIDATE,
        payload: {
          errors: fieldErrors,
        },
      });

      return {
        error: true,
      };
    }

    return {
      error: false,
    };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const token = recaptchaRef.current.getValue();
    recaptchaRef.current.reset();

    const validate = doValidation();
    if (validate.error) {
      return;
    }

    setPage({
      ...page,
      formLoading: true,
    });

    try {
      await auth.register({
        first_name: state.forms.first_name.value,
        last_name: state.forms.last_name.value,
        company_name: state.forms.company.value,
        account_number: state.forms.account_number.value,
        email: state.forms.email.value,
        city: state.forms.city.value,
        state: state.forms.state.value,
        zipcode: state.forms.zip_code.value,
        password: state.forms.password.value,
        password_confirmation: state.forms.confirm_password.value,
        sales_rep_name: state.forms.sales_rep_name.value,
        token: token,
      });
      dispatch({
        type: SETTOAST,
        forms: initForms.forms,
        payload: {
          status: 'success',
          message: 'Thank you for registration to our website.',
        },
      });
      // await doLogin();
      setPage({
        ...page,
        formLoading: false,
        thankYou: true,
      });
    } catch (err) {
      dispatch({
        type: SETTOAST,
        payload: {
          status: 'danger',
          message: "We're sorry, can not submit your request.",
        },
      });
      setPage({
        ...page,
        formLoading: false,
      });
    }
  };

  const linkTerm = () => <Link to="/terms-of-use">Terms of Use.</Link>;

  if (page.loading) return <Loader />;

  return (
    <>
      {page.thankYou ? (
        <ThankYou />
      ) : (
        <>
          <Title>{page.title}</Title>
          <p>
            Complete the signup form to access content. Please use strong passwords of at least 6 characters with a mix
            of upper and lowercase letters and numbers.
          </p>
          <div className="row">
            <div className="col-12 col-md-8">
              {state.toast.status !== '' && (
                <div className="row mb-4">
                  <div className="col-12">
                    <div className={`alert alert-${state.toast.status}`}>{state.toast.message}</div>
                  </div>
                </div>
              )}
              <form action="" onSubmit={handleSubmit}>
                <div className="form-row">
                  {Object.keys(state.forms).length > 0 &&
                    Object.keys(state.forms).map((k) => {
                      if (
                        state.forms[k].type === 'text' ||
                        state.forms[k].type === 'password' ||
                        state.forms[k].type === 'email'
                      ) {
                        return (
                          <Input
                            key={k}
                            type={state.forms[k].type}
                            name={k}
                            label={state.forms[k].label}
                            onChange={handleInputChange}
                            errors={state.forms[k].errors}
                            value={state.forms[k].value}
                            groupClassName={state.forms[k].col}
                          />
                        );
                      }
                    })}
                </div>
                <div className="form-row">
                  <Checkbox
                    name="terms"
                    label={() => {
                      return (
                        <>
                          {state.forms.terms.label} {linkTerm()}
                        </>
                      );
                    }}
                    errors={state.forms.terms.errors}
                    onChange={handleInputChange}
                    value="1"
                    groupClassName={'col-12'}
                  />
                </div>
                <div className="form-row mb-4">
                  <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} ref={recaptchaRef} />
                </div>
                <div className="form-row">
                  <div className="col-12">
                    <Button
                      type="submit"
                      className={`text-uppercase`}
                      hasLoader={true}
                      onLoading={page.formLoading}
                      disabled={page.formLoading}
                    >
                      Register
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </>
      )}
    </>
  );
};
