import React from 'react';
import Label from './Label';

const Textarea = props => {
    const formInvalidClass = props.errors.status ? ' is-invalid' : '';
    const classNames = ' ' + props.className + formInvalidClass;

    return (
        <div className={`form-group ${props.groupClassName}`}>
            {props.label !== '' && <Label {...props} />}
            <textarea
                className={`form-control${classNames}`}
                name={props.name}
                onChange={props.onChange}
                value={props.value}
                rows={props.rows}
                cols={props.cols}
            />
            {props.errors.status && <div className="invalid-feedback">{props.errors.message}</div>}
        </div>
    );
};

Textarea.defaultProps = {
    groupClassName: 'col-12',
    className: '',
    required: false,
    onChange: () => {},
    errors: {},
    label: '',
    rows: 5,
    cols: 5
};

export default Textarea;
