import React from 'react';
import Label from './Label';

const Checkbox = props => {
    const formInvalidClass = props.errors.status ? ' is-invalid' : '';
    const classNames = ' ' + props.className + formInvalidClass;
    return (
        <div className={`form-group ${props.groupClassName}`}>
            <div className={`form-check`}>
                <input
                    className={`form-check-input${classNames}`}
                    type="checkbox"
                    value={props.value}
                    onChange={props.onChange}
                    name={props.name}
                />
                {props.label !== '' && <Label {...props} labelClassName="form-check-label" />}
                {props.errors.status && (
                    <div className="invalid-feedback">{props.errors.message}</div>
                )}
            </div>
        </div>
    );
};
Checkbox.defaultProps = {
    groupClassName: 'col-12',
    className: '',
    required: false,
    onChange: () => {},
    errors: {},
    label: ''
};

export default Checkbox;
