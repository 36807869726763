import React from 'react';
import { Button } from '../../ui';

import { Consumer } from '../../context';

export default () => {
  const handleClickMenu = (fn, open) => e => {
    fn(!open);
  };
  return (
    <Consumer>
      {value => {
        return (
          <div>
            <Button color="transparent" onClick={handleClickMenu(value.handleMobileMenu, value.mobileMenuOpen)}>
              <span className="humberger-icon" />
            </Button>
          </div>
        );
      }}
    </Consumer>
  );
};
