import React, { useEffect } from 'react';
import { Title } from '../../components';
import useFetchSearch from '../../api/useFetchSearch';
import { Loader } from '../../ui';
import { Folder, Files } from '../Categories/views';
import { NoData } from '../../components';

import { Provider } from '../../tableContext';
import { Link } from 'react-router-dom';

const Search = props => {
  const { state, page, isForbiden, sorts, keywords } = useFetchSearch(props);
  useEffect(() => {
    let token = localStorage.getItem('token');
    if (!token) {
      props.history.push('/login');
    }
  }, []);
  if (page.loading) return <Loader />;
  return (
    <>
      <Title>
        Search: <span className="text-primary">{keywords}</span>
      </Title>
      <div className="row">
        <div className="col-12">
          {page.noData ? (
            (isForbiden && (
              <>
                <h1 className="mb-5">403, Access Denied</h1>
                <h2>Sorry, you don't have permission to access this folder/file.</h2>
                <p>
                  For more information please contact <Link to="contact">our support</Link>.
                </p>
              </>
            )) || <NoData />
          ) : (
            <Provider sort={sorts} data={state.data}>
              {page.viewType === 'folder' && <Folder payload={state} page={page} />}
              {page.viewType === 'file' && <Files payload={state} page={page} />}
            </Provider>
          )}
        </div>
      </div>
    </>
  );
};

export default Search;
