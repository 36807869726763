const LAST_PAGE = 'lastPage';
const setLastPage = location => {
  const { pathname, search = '' } = location;
  if (pathname !== '/login') {
    localStorage.setItem(LAST_PAGE, pathname + search);
  }
};

const getLastPage = () => {
  if (localStorage.getItem(LAST_PAGE)) {
    return localStorage.getItem(LAST_PAGE);
  }
  return '/';
};

export default { setLastPage, getLastPage };
