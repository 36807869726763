import React from 'react';
import { withRouter, Link } from 'react-router-dom';

import { breadcrumbUtil } from '../../utilities';

const Breadcrumb = props => {
  if (!props.location || !props.location.search) return null;
  let toLink;

  const listed = breadcrumbUtil.breadcrumbLists(props.location.search);
  const listedMobile = breadcrumbUtil.mobileBreadcrumbList(props.location.search, props.location.pathname);

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <ol className="breadcrumb align-items-center d-none d-md-flex">
            <li className="breadcrumb-item ">
              <Link to="/">Home</Link>
            </li>
            {listed.map((r, i) => {
              if (r.category) {
                toLink = `${props.location.pathname}?category=${r.category.id}&category_name=${r.category.name}`;
                if (i === listed.length - 1) {
                  return (
                    <li className="breadcrumb-item" key={r.category.id}>
                      {r.category.name}
                    </li>
                  );
                }
                return (
                  <li className="breadcrumb-item" key={r.category.id}>
                    <Link to={toLink}>{r.category.name}</Link>
                  </li>
                );
              }
              if (r.folders) {
                return r.folders.map((f, j) => {
                  toLink += `&folder[]=${f.id}&folder_name[]=${f.name}`;
                  if (j === r.folders.length - 1) {
                    return (
                      <li className="breadcrumb-item" key={f.id}>
                        {f.name}
                      </li>
                    );
                  }
                  return (
                    <li className="breadcrumb-item" key={f.id}>
                      <Link to={toLink}>{f.name}</Link>
                    </li>
                  );
                });
              }
            })}
          </ol>
          <ol className="breadcrumb align-items-center d-md-none d-flex mobile-breadcrumb">
            <li className="breadcrumb-item">
              <Link to={listedMobile.link}>{listedMobile.name}</Link>
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Breadcrumb);
