import React from 'react';
import { Link } from 'react-router-dom';

export default () => {
  return (
    <div className="col-12">
      <h1 className="page-not-found">404 | Page not found</h1>
      <p>
        We're sorry, the page you requested could not be found. Please go back to the <Link to="/">homepage</Link>.
      </p>
    </div>
  );
};
