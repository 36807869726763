import http from './httpService';
import { errorMessage } from '../utilities';

const API_ENDPOINT = '/api/contact-us';

const submitContactForm = async (params) => {
  try {
    await http.post(API_ENDPOINT, { ...params });
  } catch (err) {
    console.log('Contact service error:', err.message);
    let message = "We're sorry, can not submit your request.";
    if (err.response) {
      message = errorMessage(err, message);
    }

    throw new Error(message);
  }
};

export default { submitContactForm };
