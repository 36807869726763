import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { downloadIcon } from '../../../utilities/iconUtil';

import { Table } from '../../../components';
import { tableUtil, download as downloadUtil } from '../../../utilities';

import { Consumer } from '../../../tableContext';
import { Loader } from '../../../ui';

const { heading: createHeading } = tableUtil;

const Folder = (props) => {
  const { page } = props;

  const [download, setDownload] = useState({
    id: '',
    progress: false,
  });

  const heading = [
    createHeading(
      'Folder Name',
      'folder_name',
      (folder) => {
        return (
          <Link to={folder.url} className={`text-dark`}>
            {folder.folder_name}
          </Link>
        );
      },
      true,
    ),
    createHeading(
      'Files',
      'total',
      (folder) => {
        return (
          <span className="file-count">
            {folder.total}
            <span className="d-md-none"> {folder.total > 1 ? 'files' : 'file'}</span>
          </span>
        );
      },
      false,
      { tdClassName: 'files' },
    ),
    createHeading(
      'Download',
      'download',
      (folder) => {
        return (
          <>
            {download.progress && download.id === folder.id ? (
              <div className="position-relative">
                <Loader size="small" />
              </div>
            ) : (
              <button onClick={downloadFolder(folder)} className="btn btn-link">
                <img src={downloadIcon} alt={`download ${folder.folder_name}`} />
              </button>
            )}
          </>
        );
      },
      false,
      { tdClassName: 'download-th' },
    ),
  ];

  const downloadFolder = (data) => async (e) => {
    try {
      setDownload({
        id: data.id,
        progress: true,
      });

      // Run download utility
      await downloadUtil.folder(data);
      setDownload({
        id: '',
        progress: false,
      });
    } catch (err) {
      console.log(err);
      setDownload({
        id: '',
        progress: false,
      });
    }
  };

  return (
    <div>
      <Consumer>
        {(value) => {
          const currOrder = {
            orderBy: value.orderBy,
            order: value.order,
          };
          return (
            <Table
              tableClass="table-categories"
              onSorting={value.handleSorting}
              data={value.data}
              heading={heading}
              sort={currOrder}
            />
          );
        }}
      </Consumer>
    </div>
  );
};

export default Folder;
