/* eslint-disable no-undef */
import axios from 'axios';
import jwtDecode from 'jwt-decode';
export const TOKEN_KEY = 'token';
export const API_ENDPOINT = '/api/onboarding';

axios.interceptors.request.use(
  (config) => {
    let token = getSessionToken();
    let current_time = new Date().getTime() / 1000;
    const jwt = getCurrentUser();

    if (jwt) {
      if (current_time > jwt.exp) {
        logout();
        window.location = '/login';
        return;
      }
    }
    if (jwt) {
      config.headers['Authorization'] = `Bearer ${token}`;
    } else {
      config.headers['Authorization'] = null;
      /*if setting null does not remove `Authorization` header then try
              delete axios.defaults.headers.common['Authorization'];
            */
    }

    return config;
  },

  (error) => {
    return Promise.reject(error);
  },
);
axios.interceptors.response.use(null, (error) => {
  const expectedError = error.response && error.response.status >= 400 && error.response.status < 500;
  if (error.response) {
    const tokenErrorMsgLists = ['TOKEN_EXPIRED', 'TOKEN_INVALID', 'TOKEN_BLACKLISTED', 'Token not provided'];
    const { data, status = 0 } = error.response || {};
    const { error: tokenError = '' } = data || {};

    if (status === 401 && tokenErrorMsgLists.indexOf(tokenError) > -1) {
      localStorage.removeItem('token');
      window.location = '/login';
    }
  }
  if (!expectedError) {
    console.log('Unexpected error:', error);
  }

  return Promise.reject(error);
});

export const getSessionToken = () => {
  return localStorage.getItem(TOKEN_KEY);
};

export const getCurrentUser = () => {
  try {
    const jwt = localStorage.getItem(TOKEN_KEY);
    if (!jwt) throw '';
    return jwtDecode(jwt);
  } catch (ex) {
    return null;
  }
};

export const logout = async () => {
  localStorage.removeItem(TOKEN_KEY);
};

export default {
  resources: axios,
  get: axios.get,
  post: axios.post,
  put: axios.put,
  patch: axios.patch,
  delete: axios.delete,
  currentUser: getCurrentUser,
  tokenKey: TOKEN_KEY,
  apiEndpoint: API_ENDPOINT,
  logout,
  getSessionToken,
};
