export const emailValidation = (email) => {
  // eslint-disable-next-line no-useless-escape
  // eslint-disable-next-line max-len
  var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};
export const validation = (schema, input) => {
  const errors = [];
  Object.keys(schema).map((o) => {
    if (schema[o]) {
      const validationType = schema[o].type.split('|');

      validationType.map((type) => {
        if (type === 'required' && !input[o].value) {
          errors.push({
            field: o,
            message: schema[o].message ? schema[o].message : 'Field ' + (schema[o].title || o) + ' is required',
          });
          return;
        }

        if (type === 'email' && !emailValidation(input[o].value)) {
          errors.push({
            field: o,
            message: schema[o].message ? schema[o].message : 'Field ' + (schema[o].title || o) + ' is invalid format',
          });
        }

        if (type === 'required' && !input[o].value && input[o].value.trim() === '') {
          errors.push({
            field: o,
            message: schema[o].message ? schema[o].message : 'Field ' + (schema[o].title || o) + ' is required',
          });
        }
        if (type === 'confirm' && input[schema[o].confirm].value !== input[o].value) {
          errors.push({
            field: o,
            message: schema[o].message ? schema[o].message : (schema[o].title || o) + " doesn't match",
          });
        }
      });
      if (schema[o].min && input[o].value.length < schema[o].min) {
        errors.push({
          field: o,
          message: schema[o].message
            ? schema[o].message
            : 'Field ' + (schema[o].title || o) + ' minimal with ' + schema[o].min + ' character',
        });
      }
      if (schema[o].max && input[o].value.length > schema[o].max) {
        errors.push({
          field: o,
          message: o.message
            ? o.message
            : 'Please insert ' + (schema[o].title || o) + ' with maximum' + schema[o].max + ' character',
        });
      }
    }
  });

  return errors;
};

export default validation;
