import { useRef, useEffect, useState, useReducer } from 'react';
import { folders } from '../services';
import queryString from 'query-string';
import { folderUtil } from '../utilities';
import reducer, { ON_FETCH } from '../reducer/folders';
import { dataMaping } from '../utilities/folderUtil';

export const useFetchFile = props => {
  const rendered = useRef(true);
  const [state, setState] = useReducer(reducer, []);
  const [page, setPage] = useState({
    loading: true,
    noData: false,
    viewType: 'folder',
  });
  const [isForbiden, setIsForbiden] = useState(false);
  const [sorts, setSorts] = useState({
    orderBy: 'folder_name',
    order: 'asc',
  });

  useEffect(() => {
    return () => {
      rendered.current = false;
    };
  }, []);

  useEffect(() => {
    const loadData = async () => {
      setPage({
        ...page,
        loading: true,
      });
      try {
        const parsed = queryString.parse(props.location.search);
        if (!parsed.category) throw new Error('Request with no paramater is not allowed!');
        const getId = folderUtil.getCurrentID(parsed);

        if (rendered.current) {
          const data = await folders.folder(getId);

          setState({
            type: ON_FETCH,
            payload: dataMaping(data, props),
          });

          const findFiles = data.find(d => {
            return d.type === 'file';
          });

          if (findFiles) {
            setSorts({
              ...sorts,
              orderBy: 'file_name',
            });
          }
          setPage({
            ...page,
            viewType: findFiles ? 'file' : 'folder',
            loading: false,
          });
        }
      } catch (err) {
        console.log(err.message);

        if (err.response) {
          if (err.response.status === 403) {
            setIsForbiden(true);
          }
        }
        setPage({
          ...page,
          loading: false,
          noData: true,
        });
      }
    };

    loadData();
  }, [props.location]);

  return {
    sorts,
    state,
    page,
    isForbiden,
  };
};
