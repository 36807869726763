import { folders } from '../services';
export const file = async data => {
  try {
    if (data.file_name) {
      const filename = data.file_name;
      const blobData = await folders.downloadFile(data.id);

      let blob = new Blob([blobData]);
      /** Download client side Hack */
      if (typeof window.navigator.msSaveBlob !== 'undefined') {
        window.navigator.msSaveBlob(blob, filename);
      } else {
        let blobURL = window.URL.createObjectURL(blob);
        let tempLink = document.createElement('a');
        tempLink.style.display = 'none';
        tempLink.href = blobURL;
        tempLink.setAttribute('download', filename);

        if (typeof tempLink.download === 'undefined') {
          tempLink.setAttribute('target', '_blank');
        }

        document.body.appendChild(tempLink);
        tempLink.click();

        // Fixes "webkit blob resource error 1"
        setTimeout(function() {
          document.body.removeChild(tempLink);
          window.URL.revokeObjectURL(blobURL);
        }, 0);
        /** End of Hacking */
      }

      return Promise.resolve();
    }
  } catch (err) {
    return Promise.reject(err);
  }
};

export const folder = async data => {
  try {
    if (data.folder_name) {
      const filename = data.folder_name;
      const blobData = await folders.downloadFolder(data.id);

      let blob = new Blob([blobData]);
      /** Download client side Hack */
      if (typeof window.navigator.msSaveBlob !== 'undefined') {
        window.navigator.msSaveBlob(blob, filename);
      } else {
        let blobURL = window.URL.createObjectURL(blob);
        let tempLink = document.createElement('a');
        tempLink.style.display = 'none';
        tempLink.href = blobURL;
        tempLink.setAttribute('download', `${filename}.zip`);

        if (typeof tempLink.download === 'undefined') {
          tempLink.setAttribute('target', '_blank');
        }

        document.body.appendChild(tempLink);
        tempLink.click();

        // Fixes "webkit blob resource error 1"
        setTimeout(function() {
          document.body.removeChild(tempLink);
          window.URL.revokeObjectURL(blobURL);
        }, 0);
        /** End of Hacking */
      }

      return Promise.resolve();
    }
    throw new Error('Please provide data to download!');
  } catch (err) {
    return Promise.reject(err);
  }
};

export default { file, folder };
