import React from 'react';
import Nav, { AuthNav } from '../Nav';
import { SearchForm } from '..';
import { Consumer } from '../../context';
import { Button } from '../../ui';

const MobileMenuModal = ({ open }) => {
  return (
    <Consumer>
      {value => {
        return (
          <>
            {open && (
              <div className="mobile-menu justify-content-end">
                <div className="menu-overlay d-flex" onClick={() => value.handleMobileMenu(!value.mobileMenuOpen)}>
                  {' '}
                </div>
                <div className="menu-wrapper">
                  <div className="row">
                    <div className="col-12">
                      <Button
                        color="transparent"
                        className="menu-close"
                        onClick={() => value.handleMobileMenu(!value.mobileMenuOpen)}
                      >
                        <span className="close-menu-icon"> </span>
                      </Button>
                    </div>
                    <div className="col-12">
                      <SearchForm />
                    </div>
                    <div className="col-12">
                      <Nav />
                    </div>
                    <div className="col-12">
                      <AuthNav />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        );
      }}
    </Consumer>
  );
};

export default MobileMenuModal;
