import React, { useReducer, useState } from 'react';
import { Link } from 'react-router-dom';
import { Title } from '../../components';
import { Input, Button, Checkbox } from '../../ui';
import { validation, lastPage } from '../../utilities';
import { formReducer as reducer, SETTOAST, ONCHANGE, ONVALIDATE } from '../../reducer/forms';

import { auth } from '../../services';
const initFormErrors = {
  status: false,
  message: '',
};
const initForms = {
  forms: {
    user_id: {
      value: '',
      errors: initFormErrors,
    },
    password: {
      value: '',
      errors: initFormErrors,
    },
    remember_me: {
      value: '',
      errors: initFormErrors,
    },
  },
};

const initState = {
  ...initForms,
  toast: {
    status: '',
    message: '',
  },
};

const formSchema = {
  user_id: {
    type: 'required|email',
    title: 'user id',
  },
  password: {
    type: 'required',
  },
};

const initPage = {
  loading: true,
  data: '',
  formLoading: false,
};

export default () => {
  const [state, dispatch] = useReducer(reducer, initState);
  const [page, setPage] = useState(initPage);

  const handleInputChange = ({ currentTarget: input }) => {
    let inputValue = input.value;

    if (input.type === 'checkbox') {
      inputValue = input.checked ? inputValue : '';
    }

    dispatch({
      type: ONCHANGE,
      payload: {
        name: input.name,
        value: inputValue,
        errors: initFormErrors,
      },
    });
  };

  const doValidation = () => {
    const validate = validation(formSchema, state.forms);
    let fieldErrors = {};

    if (validate.length > 0) {
      validate.map((v) => {
        fieldErrors = {
          ...fieldErrors,
          [v.field]: {
            ...state.forms[v.field],
            errors: {
              status: true,
              message: v.message,
            },
          },
        };
      });

      dispatch({
        type: ONVALIDATE,
        payload: {
          errors: fieldErrors,
        },
      });

      return {
        error: true,
      };
    }

    return {
      error: false,
    };
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validate = doValidation();
    if (validate.error) {
      return;
    }

    setPage({
      ...page,
      formLoading: true,
    });

    try {
      await auth.login({
        email: state.forms.user_id.value,
        password: state.forms.password.value,
        redirect: lastPage.getLastPage(),
      });
    } catch (err) {
      dispatch({
        type: SETTOAST,
        payload: {
          status: 'danger',
          message: err.message,
        },
      });
    }

    setPage({
      ...page,
      formLoading: false,
    });
  };

  return (
    <>
      <Title>Log In To Your Account</Title>
      <div className="row">
        <div className="col-12 col-md-7 col-lg-5">
          {state.toast.status !== '' && (
            <div className="row mb-4">
              <div className="col-12">
                <div className={`alert alert-${state.toast.status}`}>{state.toast.message}</div>
              </div>
            </div>
          )}
          <form action="" onSubmit={handleSubmit}>
            <div className="form-row">
              <Input
                name="user_id"
                label="User ID"
                onChange={handleInputChange}
                errors={state.forms.user_id.errors}
                value={state.forms.user_id.value}
              />
              <Input
                name="password"
                label="Password"
                type="password"
                onChange={handleInputChange}
                errors={state.forms.password.errors}
                value={state.forms.password.value}
              />
            </div>
            <div className="form-row mb-4">
              <Checkbox
                name="remember_me"
                label="Remember me"
                onChange={handleInputChange}
                value="1"
                groupClassName={'col-md-6 col-12'}
              />
              <div className="col-md-6 text-right">
                <Link to="/forget-password">Forget Password</Link>
              </div>
            </div>
            <div className="form-row">
              <div className="col-6">
                <Button
                  type="submit"
                  className={`text-uppercase btn-block`}
                  hasLoader={true}
                  onLoading={page.formLoading}
                  disabled={page.formLoading}
                >
                  Login
                </Button>
              </div>
              <div className="col-6">
                <Link className="btn btn-outline-primary text-uppercase btn-block" to="/register">
                  Register
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
