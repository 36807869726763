import React from 'react';
import Label from './Label';

const Select = props => {
    const formInvalidClass = props.errors.status ? ' is-invalid' : '';
    const classNames = ' ' + props.className + formInvalidClass;

    return (
        <div className={`form-group ${props.groupClassName}`}>
            {props.label !== '' && <Label {...props} />}
            <select
                className={`custom-select${classNames}`}
                name={props.name}
                onChange={props.onChange}
                value={props.value}>
                {props.options.map((o, i) => {
                    return (
                        <option value={o[0]} key={i}>
                            {o[1] || o[0]}
                        </option>
                    );
                })}
            </select>
            {props.errors.status && <div className="invalid-feedback">{props.errors.message}</div>}
        </div>
    );
};

Select.defaultProps = {
    groupClassName: 'col-12',
    className: '',
    required: false,
    onChange: () => {},
    errors: {},
    options: []
};

export default Select;
