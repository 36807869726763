import React from 'react';

const drawContent = (a, b) => {
  if (a.content) {
    return a.content(b);
  }
  return b[a.key];
};

const titleContent = title => {
  if (typeof title === 'function') {
    return title();
  }
  return title;
};
const TableResponsive = props => {
  return <div className="table-responsive">{props.children}</div>;
};

const handleSort = (fn, sort) => e => {
  fn(sort);
};
const TableContent = props => {
  const { tableClass, data, heading, sort } = props || {};

  return (
    <table className={`table ${tableClass || ''}`}>
      <thead>
        <tr>
          {heading.map(h => {
            return (
              <th key={h.key}>
                {h.sorting ? (
                  <button
                    onClick={handleSort(props.onSorting, {
                      orderBy: h.key,
                      order: sort.order.toLowerCase(),
                    })}
                    className={`btn btn-link btn-sorting ${sort.order.toLowerCase()}`}
                  >
                    {titleContent(h.title)}
                  </button>
                ) : (
                  <span>{titleContent(h.title)}</span>
                )}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {data &&
          data.map(d => {
            return (
              <tr key={d.id}>
                {heading.map(h => {
                  const attrClassName = h.attr.tdClassName || '';
                  return (
                    <td key={d.id + h.title} className={attrClassName}>
                      {drawContent(h, d)}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        {(!data || data.length === 0) && (
          <tr>
            <td colSpan={heading.length}>No data available</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};
const Table = props => {
  return (
    <>
      {(props.responsive && (
        <TableResponsive>
          <TableContent {...props} />
        </TableResponsive>
      )) || <TableContent {...props} />}
    </>
  );
};

Table.defaultProps = {
  responsive: true,
};

export default Table;
