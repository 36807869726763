import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { previewIcon, downloadIcon } from '../../../utilities/iconUtil';

import { Table, Modal } from '../../../components';
import { tableUtil, folderUtil, download as downloadUtil } from '../../../utilities';
import { Consumer } from '../../../tableContext';
import { folders } from '../../../services';
import { Loader } from '../../../ui';
import { ViewerImg, ViewerHeader } from '../../../components';

const createHeading = tableUtil.heading;

const Files = props => {
  const [state, setState] = useState({
    modalOpen: false,
    modalData: '',
    modalHeader: '',
    previewLoading: false,
  });

  const [download, setDownload] = useState({
    id: '',
    progress: false,
  });

  const heading = [
    createHeading('Type', 'type', file => {
      if (file.file_name) return <img src={folderUtil.fileIcon(file.file_name)} />;
      return <img src={folderUtil.fileIcon('folder')} />;
    }),
    createHeading(
      () => {
        return (
          <>
            File <span className="d-hide d-md-inline">Name</span>
          </>
        );
      },
      'file_name',
      file => {
        if (file.file_name)
          return (
            <>
              <img src={folderUtil.fileIcon(file.file_name)} className="d-md-none" /> {file.file_name}
            </>
          );
        return (
          <>
            <img src={folderUtil.fileIcon('folder')} className="d-md-none" />{' '}
            <Link to={file.url} className={`text-dark`}>
              {file.folder_name}
            </Link>
          </>
        );
      },
      true,
      {
        tdClassName: 'text-left',
      },
    ),
    createHeading('Preview', 'preview', file => {
      if (file.file_name)
        return (
          <button onClick={handleModalOpen(file)} className="btn btn-link">
            <img src={previewIcon} alt={`preview`} />
          </button>
        );
    }),
    createHeading('Download', 'download', file => {
      return (
        <>
          {download.progress && download.id === file.id ? (
            <div className="position-relative">
              <Loader size="small" />
            </div>
          ) : (
            <button onClick={downloadFile(file)} className="btn btn-link">
              <img src={downloadIcon} alt={`download ${file.file_name || file.folder_name}`} />
            </button>
          )}
        </>
      );
    }),
  ];

  const downloadFile = data => async e => {
    try {
      setDownload({
        id: data.id,
        progress: true,
      });
      await downloadUtil.file(data);

      setDownload({
        id: '',
        progress: false,
      });
    } catch (err) {
      console.log(err);
      setDownload({
        id: '',
        progress: false,
      });
    }
  };

  const handleModalClose = () => {
    setState({
      modalOpen: false,
      modalData: '',
      modalHeader: '',
    });
  };

  const handleModalOpen = data => async e => {
    e.preventDefault();
    let imageUrl = '';
    setState({
      modalOpen: true,
      modalData: <ViewerImg data={data} imgString={imageUrl} previewLoading={true} />,
      modalHeader: <ViewerHeader data={data.file_name} onClose={handleModalClose} />,
      previewLoading: true,
    });
    if (!folderUtil.excludePreview(data.file_name)) {
      let imagesChache = {};
      if (localStorage.getItem('imagesStorage')) {
        imagesChache = JSON.parse(localStorage.getItem('imagesStorage'));
      }

      if (imagesChache[data.id]) {
        imageUrl = imagesChache[data.id];
      } else {
        const blobData = await folders.downloadFile(data.id, true);

        let urlCreator = window.URL || window.webkitURL;
        imageUrl = urlCreator.createObjectURL(blobData);

        const imagesStorage = JSON.stringify({ ...imagesChache, [data.id]: imageUrl });

        localStorage.setItem('imagesStorage', imagesStorage);
      }
    }

    setState({
      modalOpen: true,
      modalData: <ViewerImg data={data} imgString={imageUrl} previewLoading={false} />,
      modalHeader: <ViewerHeader data={data.file_name} onClose={handleModalClose} />,
      previewLoading: false,
    });
  };

  return (
    <div>
      <Consumer>
        {value => {
          const currOrder = {
            orderBy: value.orderBy,
            order: value.order,
          };
          return (
            <Table
              tableClass="table-categories table-files"
              onSorting={value.handleSorting}
              data={value.data}
              heading={heading}
              sort={currOrder}
            />
          );
        }}
      </Consumer>
      <Modal
        open={state.modalOpen}
        data={{ body: state.modalData, header: state.modalHeader }}
        onClose={handleModalClose}
        className="preview-modal"
      />
    </div>
  );
};

export default Files;
