import React from 'react';
import Label from './Label';

const Input = props => {
    const formInvalidClass = props.errors.status ? ' is-invalid' : '';
    const classNames = ' ' + props.className + formInvalidClass;

    return (
        <div className={`form-group ${props.groupClassName}`}>
            {props.label !== '' && <Label {...props} />}
            <input
                type={props.type}
                className={`form-control${classNames}`}
                name={props.name}
                onChange={props.onChange}
                value={props.value}
            />
            {props.errors.status && <div className="invalid-feedback">{props.errors.message}</div>}
        </div>
    );
};

Input.defaultProps = {
    type: 'text',
    groupClassName: 'col-12',
    className: '',
    required: false,
    onChange: () => {},
    errors: {},
    label: ''
};

export default Input;
