import React from 'react';
import { folderUtil } from '../../utilities';
import { Loader } from '../../ui';

export const ViewerImg = ({ data, imgString, previewLoading }) => {
  if (previewLoading)
    return (
      <div className="position-relative modal-preview-loading">
        <Loader />
      </div>
    );
  if (folderUtil.excludePreview(data.file_name)) return <span>No preview available for this file.</span>;
  return <img src={imgString} className="img-fluid" />;
};

export const ViewerHeader = ({ data, onClose }) => {
  return (
    <div className="container">
      <div className="row justify-content-between">
        <div className="col-10">{data}</div>
        <div className="col-2 text-right">
          <button onClick={onClose} className="btn btn-link btn-modal-close">
            x
          </button>
        </div>
      </div>
    </div>
  );
};
