export const initFormErrors = {
  status: false,
  message: '',
};
export const formSchema = {
  first_name: {
    type: 'required',
    title: 'first name',
  },
  last_name: {
    type: 'required',
    title: 'last name',
  },
  city: {
    type: 'required',
  },
  state: {
    type: 'required',
  },
  zipcode: {
    type: 'required',
    title: 'zip code',
  },
  sales_rep_name: {
    type: 'required',
    title: 'sales rep name',
  },
  email: {
    type: 'required|email',
  },
  company_name: {
    type: 'required',
    title: 'company name',
  },
};

export const initForms = {
  forms: {
    first_name: {
      value: '',
      errors: initFormErrors,
      label: 'First Name',
      type: 'text',
      col: 'col-md-12',
    },
    last_name: {
      value: '',
      errors: initFormErrors,
      label: 'Last Name',
      type: 'text',
      col: 'col-md-12',
    },
    city: {
      value: '',
      errors: initFormErrors,
      label: 'City',
      type: 'text',
      col: 'col-md-12',
    },
    state: {
      value: '',
      errors: initFormErrors,
      label: 'State',
      type: 'text',
      col: 'col-md-12',
    },
    zipcode: {
      value: '',
      errors: initFormErrors,
      label: 'Zip Code',
      type: 'text',
      col: 'col-md-12',
    },
    sales_rep_name: {
      value: '',
      errors: initFormErrors,
      label: 'Sales Rep Name',
      type: 'text',
      col: 'col-md-12',
    },
    email: {
      value: '',
      errors: initFormErrors,
      label: 'Email Address',
      type: 'email',
      col: 'col-md-12',
    },
    company_name: {
      value: '',
      errors: initFormErrors,
      label: 'Company Name',
      type: 'text',
      col: 'col-md-12',
    },
  },
};
