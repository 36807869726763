import React from 'react';
import { Link } from 'react-router-dom';

export default ({ data }) => {
  if (!data.attr) return null;

  let style = {};
  if (data.attr.backgroundImg) {
    style = {
      backgroundImage: `url(${data.attr.backgroundImg})`,
    };
  }

  return (
    <div
      className={`col-12 ${data.attr.wrapCol ? 'col-md-8' : 'col-sm-6 col-md-4'} ${data.attr.padding} ${
        data.attr.margin
      }`}
      key={data.id}
    >
      <Link to={data.url} className={`tile-menu bg-image d-flex ${data.alias}`} style={style}>
        {(data.attr.textCard && <img src={data.attr.textCard} alt={data.name} className="img-fluid tile-name" />) || (
          <span className="tile-name text">{data.name}</span>
        )}
        <div className="hover-background"></div>
      </Link>
    </div>
  );
};
