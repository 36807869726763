import React from 'react';
import { Switch, Route } from 'react-router-dom';

// import { ProtectedRouteAdmin } from "../../components/ProtectedRoute"
import { ProtectedRoute } from '../components';
import { Categories, NotFound } from '../pages';

const RouterCategories = () => {
  return (
    <Switch>
      <ProtectedRoute path={`/categories`} exact render={props => <Categories {...props} />} />
      {/* <ProtectedRoute
        path={`/categories/:folderid/:folder_slug/:subfolder_id/:subfolder_slug/:files_id/:files_slug`}
        exact
        render={props => <Files {...props} />}
      />
      <ProtectedRoute
        path={`/categories/:folderid/:folder_slug/:subfolder_id/:subfolder_slug`}
        exact
        render={props => <Categories {...props} />}
      /> */}
      {/* <ProtectedRoute path={`/categories/:folderid/:folder_slug`} exact render={props => 
        <Categories {...props} />} /> */}
      <Route component={NotFound} />
    </Switch>
  );
};

export default RouterCategories;
