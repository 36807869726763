import React from 'react';
import { Link } from 'react-router-dom';

export default props => {
  return (
    <h2>
      {props.children || (
        <span>
          No data available. You can go back to our <Link to="/">homepage</Link>.
        </span>
      )}
    </h2>
  );
};
