import React from 'react';
import { NavLink as Link } from 'react-router-dom';

const Nav = () => {
  return (
    <ul className="nav nav-page text-uppercase ml-min-1 align-items-end">
      <li className="nav-item">
        <Link exact className="nav-link" activeClassName="active" to="/">
          Home
        </Link>
      </li>
      <li className="nav-item">
        <Link activeClassName="active" className="nav-link" to="/about-us">
          About Us
        </Link>
      </li>
      <li className="nav-item">
        <Link className="nav-link" activeClassName="active" to="/contact">
          Contact Us
        </Link>
      </li>
    </ul>
  );
};

export default Nav;
