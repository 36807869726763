import React from 'react';
import { Link } from 'react-router-dom';
import { Title } from '../../components';

const ThankYou = () => {
  return (
    <div className="row">
      <div className="col-12">
        <Title>Thank you.</Title>
      </div>
      <div className="col-12">
        <p>Please check your email for further instructions on how to complete your account setup.</p>
        <p>
          Having trouble? <Link to="contact">Contact us</Link>
          <br />
          Back to <Link to="/">homepage</Link>
        </p>
      </div>
    </div>
  );
};

export default ThankYou;
