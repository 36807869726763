import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import AboutTileMenu from '../AboutTileMenu';

const dt = new Date();
const Footer = props => {
  return (
    <>
      {props.location.pathname === '/about' && (
        <div className="d-none d-md-block bg-secondary py-3 mb-5">
          <AboutTileMenu />
        </div>
      )}
      <footer className="footer container">
        <div className="row">
          <div className="col-12">
            <Link to="/terms-of-use">Terms &amp; Conditions</Link>
          </div>
          <div className="col-12">
            <address>&copy; {dt.getFullYear()} Pioneer Electronics (USA) Inc.</address>
          </div>
        </div>
      </footer>
    </>
  );
};

export default withRouter(Footer);
