import React from 'react';

const Loader = ({size, color}) => {
    switch (size) {
        case 'small':
            return <div className={`lds-dual-ring sm ${color}`}></div>;
        case 'large':
            return <div className={`lds-dual-ring lg ${color}`}></div>;
        default:
            return <div className={`lds-dual-ring ${color}`}></div>;
    }
};

Loader.defaultProps = {
    size: 'medium',
    color: 'primary'
};
export default Loader;
