import 'react-app-polyfill/ie11';
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import DefaultLayout, { LayoutWithBreadcrumb } from './layout';
import { Logout } from './pages';

import './sass/app.scss';

class App extends Component {
  render() {
    return (
      <Router>
        <Router>
          <Switch>
            <Route path={`/`} exact component={DefaultLayout} />
            <Route path={`/logout`} exact component={Logout} />
            <Route path={`/categories/:category?`} component={LayoutWithBreadcrumb} />
            <Route path={`/:pageName`} component={DefaultLayout} />
          </Switch>
        </Router>
      </Router>
    );
  }
}
export default App;
