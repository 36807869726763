import React from 'react';
import HeaderTop from './HeaderTop';
import HeaderNav from './HeaderNav';

const Header = () => {
  return (
    <>
      <HeaderTop />
      <HeaderNav />
    </>
  );
};

export default Header;
