import React, { useEffect, useReducer, useState } from 'react';
import reducer, { PAGE_FETCH } from '../../reducer/pages';
import { pages } from '../../services';
import { Title } from '../../components';
import { Loader } from '../../ui';
import NotFound from '../NotFound';
import { ComponentWithHTML } from '../../utilities';

export default props => {
  const { params } = props.match || {};

  const [state, setState] = useReducer(reducer, []);
  const [page, setPage] = useState({
    loading: true,
    noData: false,
  });

  useEffect(() => {
    const fetchPage = async slug => {
      try {
        if (!slug) throw new Error('Slug can not be empty!');

        const data = await pages.show(slug);
        setState({
          type: PAGE_FETCH,
          payload: data,
        });
        setPage({
          loading: false,
          noData: false,
        });
      } catch (err) {
        console.log(err);

        setPage({
          loading: false,
          noData: true,
        });
      }
    };

    fetchPage(params.slug);
  }, [props.location]);

  const Content = props => {
    return <>{props.children}</>;
  };

  if (page.loading) return <Loader />;

  if (page.noData)
    return (
      <div className="row">
        <NotFound />
      </div>
    );
  return (
    <>
      <Title>{state.data.title || ''}</Title>
      <div className="row">
        <div className="col-12">
          <ComponentWithHTML component="div" html={state.data.content || ''} />
        </div>
      </div>
    </>
  );
};
