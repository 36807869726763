import React, { useState, useEffect, useReducer } from 'react';
import reducer, { SORT_DATA } from './reducer/tables';

const Context = React.createContext();

export const Provider = props => {
  const { sort, data } = props;
  const [loadData, setLoadData] = useReducer(reducer, data);
  const [state, setState] = useState({
    data: loadData,
    orderBy: sort.orderBy,
    order: sort.order,
    reverseSort: currSort => {
      return currSort.order === 'desc' ? 'asc' : 'desc';
    },
    handleSorting: sort => {
      const getOrder = sort.order === 'desc' ? 'asc' : 'desc';

      setLoadData({
        type: SORT_DATA,
        order: {
          orderBy: sort.orderBy,
          order: getOrder,
        },
        payload: data,
      });

      setState({
        ...state,
        orderBy: sort.orderBy,
        order: getOrder,
        data: loadData,
      });
    },
  });

  useEffect(() => {
    setLoadData({
      type: SORT_DATA,
      order: sort,
      payload: data,
    });
    setState({
      ...state,
      data: loadData,
    });
  }, [data]);

  return <Context.Provider value={state}>{props.children}</Context.Provider>;
};

export const Consumer = Context.Consumer;
