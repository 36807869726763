import React, { useEffect } from 'react';
import { Loader } from '../../ui';
import { Folder, Files } from './views';
import { NoData } from '../../components';
import { useFetchFile } from '../../api/useFetchFile';

import { Provider } from '../../tableContext';
import { Link } from 'react-router-dom';

const Categories = props => {
  const { state, page, isForbiden, sorts } = useFetchFile(props);
  useEffect(() => {
    localStorage.removeItem('imagesStorage');
  }, []);

  if (page.loading) return <Loader />;

  return (
    <>
      <div className="row">
        <div className="col-12">
          {page.noData ? (
            (isForbiden && (
              <>
                <h1 className="mb-5">403, Access Denied</h1>
                <h2>Sorry, you don't have permission to access this folder/file.</h2>
                <p>
                  For more information please contact <Link to="contact">our support</Link>.
                </p>
              </>
            )) || <NoData />
          ) : (
            <Provider sort={sorts} data={state.data}>
              {page.viewType === 'folder' && <Folder payload={state} page={page} />}
              {page.viewType === 'file' && <Files payload={state} page={page} />}
            </Provider>
          )}
        </div>
      </div>
    </>
  );
};

export default Categories;
