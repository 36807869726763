import http from './httpService';
import { errorMessage } from '../utilities';

const API_ENDPOINT = '/api';

export const updateProfile = async (params) => {
  try {
    await http.put(API_ENDPOINT + '/profile', { ...params });
  } catch (err) {
    console.log('Register service error:', err.message);
    let message = "We're sorry, can not submit your request.";
    if (err.response) {
      message = errorMessage(err, message);
    }

    throw new Error(message);
  }
};
export const editProfile = async () => {
  try {
    const { data } = await http.get(API_ENDPOINT + '/me');
    return data;
  } catch (err) {
    console.log('Register service error:', err.message);
    let message = "We're sorry, can not submit your request.";
    if (err.response) {
      message = errorMessage(err, message);
    }

    throw new Error(message);
  }
};
