import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo.png';
import SearchForm from '../SearchForm';
import MobileMenu from '../MobileMenu';

const HeaderTop = () => {
  return (
    <header className="bg-dark py-4">
      <div className="container">
        <div className="row justify-content-between align-items-md-center align-items-end">
          <div className="col-8 col logo">
            <Link to="/">
              <img src={logo} alt="Pioneer" className="img-fluid" />
            </Link>
          </div>
          <div className="col-4">
            <div className="d-none d-md-block">
              <SearchForm />
            </div>
            <div className="d-flex justify-content-end d-md-none">
              <MobileMenu />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
export default HeaderTop;
