import React, {useReducer} from 'react';
import {Title} from '../../components';
import {Input, Button} from '../../ui';
import {validation} from '../../utilities';
import {formReducer as reducer, SETTOAST, ONCHANGE, ONVALIDATE} from '../../reducer/forms';

import {auth} from '../../services';
const initFormErrors = {
    status: false,
    message: ''
};
const initForms = {
    forms: {
        email: {
            value: '',
            errors: initFormErrors
        }
    }
};

const initState = {
    ...initForms,
    toast: {
        status: '',
        message: ''
    }
};

const formSchema = {
    email: {
        type: 'required|email'
    }
};

export default () => {
    const [state, dispatch] = useReducer(reducer, initState);

    const handleInputChange = ({currentTarget: input}) => {
        let inputValue = input.value;

        if (input.type === 'checkbox') {
            inputValue = input.checked ? inputValue : '';
        }

        dispatch({
            type: ONCHANGE,
            payload: {
                name: input.name,
                value: inputValue,
                errors: initFormErrors
            }
        });
    };

    const doValidation = () => {
        const validate = validation(formSchema, state.forms);
        let fieldErrors = {};

        if (validate.length > 0) {
            validate.map(v => {
                fieldErrors = {
                    ...fieldErrors,
                    [v.field]: {
                        ...state.forms[v.field],
                        errors: {
                            status: true,
                            message: v.message
                        }
                    }
                };
            });

            dispatch({
                type: ONVALIDATE,
                payload: {
                    errors: fieldErrors
                }
            });

            return {
                error: true
            };
        }

        return {
            error: false
        };
    };
    const handleSubmit = async e => {
        e.preventDefault();
        const validate = doValidation();
        if (validate.error) {
            return;
        }

        try {
            await auth.forgotPassword({
                email: state.forms.email.value
            });
            dispatch({
                type: SETTOAST,
                forms: initForms.forms,
                payload: {
                    status: 'success',
                    message: 'Forgot password link has been sent to your email.'
                }
            });
        } catch (err) {
            dispatch({
                type: SETTOAST,
                payload: {
                    status: 'danger',
                    message: err.message
                }
            });
        }
    };

    return (
        <>
            <Title>Forgot Password</Title>
            <p className="mb-4">
                Enter the email address on your account. You will receive a verification email that
                will allow you to change your password.
            </p>
            <div className="row">
                <div className="col-12 col-md-6 col-lg-4">
                    {state.toast.status !== '' && (
                        <div className="row mb-4">
                            <div className="col-12">
                                <div className={`alert alert-${state.toast.status}`}>
                                    {state.toast.message}
                                </div>
                            </div>
                        </div>
                    )}
                    <form action="" onSubmit={handleSubmit}>
                        <div className="form-row">
                            <Input
                                name="email"
                                label="Email Address"
                                onChange={handleInputChange}
                                required={true}
                                errors={state.forms.email.errors}
                                value={state.forms.email.value}
                            />
                        </div>
                        <div className="form-row">
                            <div className="col-6">
                                <Button type="submit" className="text-uppercase px-4">
                                    Send
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};
