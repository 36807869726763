import React, { useReducer, useState } from 'react';
import { Title } from '../../components';
import { Input, Button, Select, Textarea } from '../../ui';
import { formReducer as reducer, SETTOAST, ONCHANGE, ONVALIDATE } from '../../reducer/forms';
import { validation } from '../../utilities';

import { contact } from '../../services';

const TOPIC = [[''], ['Car', 'Car'], ['Cables', 'Cables']];

const initFormErrors = {
  status: false,
  message: '',
};

const initForms = {
  forms: {
    name: {
      value: '',
      errors: initFormErrors,
    },
    company_name: {
      value: '',
      errors: initFormErrors,
    },
    email: {
      value: '',
      errors: initFormErrors,
    },
    phone: {
      value: '',
      errors: initFormErrors,
    },
    topic: {
      value: '',
      errors: initFormErrors,
    },
    message: {
      value: '',
      errors: initFormErrors,
    },
  },
};

const initState = {
  ...initForms,
  toast: {
    status: '',
    message: '',
  },
};

const formSchema = {
  name: {
    type: 'required',
  },
  email: {
    type: 'required|email',
  },
  topic: {
    type: 'required',
  },
  message: {
    type: 'required',
  },
};

export default () => {
  const [state, dispatch] = useReducer(reducer, initState);
  const [sending, setSending] = useState(false);

  const handleInputChange = ({ currentTarget: input }) => {
    let inputValue = input.value;

    if (input.type === 'checkbox') {
      inputValue = input.checked ? inputValue : '';
    }

    dispatch({
      type: ONCHANGE,
      payload: {
        name: input.name,
        value: inputValue,
        errors: initFormErrors,
      },
    });
  };

  const doValidation = () => {
    const validate = validation(formSchema, state.forms);
    let fieldErrors = {};

    if (validate.length > 0) {
      validate.map((v) => {
        fieldErrors = {
          ...fieldErrors,
          [v.field]: {
            ...state.forms[v.field],
            errors: {
              status: true,
              message: v.message,
            },
          },
        };
      });

      dispatch({
        type: ONVALIDATE,
        payload: {
          errors: fieldErrors,
        },
      });

      return {
        error: true,
      };
    }

    return {
      error: false,
    };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSending(true);
    const validate = doValidation();
    if (validate.error) {
      return;
    }

    try {
      await contact.submitContactForm({
        name: state.forms.name.value,
        company_name: state.forms.company_name.value,
        phone: state.forms.phone.value,
        email: state.forms.email.value,
        topic: state.forms.topic.value,
        message: state.forms.message.value,
      });
      dispatch({
        type: SETTOAST,
        forms: initForms.forms,
        payload: {
          status: 'success',
          message: 'Thank you for contacting us!',
          // message: "We're sorry. Unable to send your request, please try again later."
        },
      });
      setSending(false);
    } catch (err) {
      dispatch({
        type: SETTOAST,
        payload: {
          status: 'danger',
          message: err.message,
        },
      });
      setSending(false);
    }
  };

  return (
    <>
      <Title>Contact Us</Title>
      <div className="row">
        <div className="col-12 col-md-8">
          {state.toast.status !== '' && (
            <div className="row mb-4">
              <div className="col-12">
                <div className={`alert alert-${state.toast.status}`}>{state.toast.message}</div>
              </div>
            </div>
          )}
          <form action="" onSubmit={handleSubmit}>
            <div className="form-row">
              <Input
                name="name"
                label="Name"
                groupClassName="col-md-6"
                onChange={handleInputChange}
                required={true}
                errors={state.forms.name.errors}
                value={state.forms.name.value}
              />
              <Input
                name="company_name"
                label="Company Name"
                groupClassName="col-md-6"
                onChange={handleInputChange}
                value={state.forms.company_name.value}
              />
            </div>
            <div className="form-row">
              <Input
                name="phone"
                label="Phone"
                groupClassName="col-md-6"
                onChange={handleInputChange}
                value={state.forms.phone.value}
              />
              <Input
                name="email"
                label="Email"
                groupClassName="col-md-6"
                onChange={handleInputChange}
                required={true}
                errors={state.forms.email.errors}
                value={state.forms.email.value}
              />
            </div>
            <div className="form-row">
              <Select
                name="topic"
                value={state.forms.topic.value}
                onChange={handleInputChange}
                label="Topic"
                errors={state.forms.topic.errors}
                options={TOPIC}
              />
            </div>
            <div className="form-row">
              <Textarea
                name="message"
                label="Message"
                rows="8"
                onChange={handleInputChange}
                required={true}
                errors={state.forms.message.errors}
                value={state.forms.message.value}
              />
            </div>
            <Button type="submit" hasLoader={true} onLoading={sending} disabled={sending}>
              SEND
            </Button>
          </form>
        </div>
      </div>
    </>
  );
};
