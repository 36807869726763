import React, { useReducer, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { categoryUtil as categories } from '../../utilities';
import reducer, { ON_FETCH } from '../../reducer/folders';
import { Loader } from '../../ui';

const TileMenu = ({ data }) => {
  if (!data.attr) return null;

  let style = {};
  if (data.attr.backgroundImg) {
    style = {
      backgroundImage: `url(${data.attr.backgroundImg})`,
    };
  }

  return (
    <div className={`col-md-24 col-12 about-tile`} key={data.id}>
      <Link to={data.url} className={`tile-menu bg-image d-flex ${data.alias}`} style={style}>
        {(data.attr.textCard && <img src={data.attr.textCard} alt={data.name} className="img-fluid tile-name" />) || (
          <span className="tile-name text">{data.name}</span>
        )}
        <div className="hover-background"></div>
      </Link>
    </div>
  );
};

const AboutTileMenu = props => {
  const [state, setState] = useReducer(reducer, []);
  const [page, setPage] = useState({
    loading: true,
  });
  useEffect(() => {
    const getAll = async () => {
      try {
        const data = await categories;

        data.sort((a, b) => {
          return a.attr.order - b.attr.order;
        });

        setState({
          type: ON_FETCH,
          payload: data.map((category, idx) => <TileMenu data={category} key={idx} />),
        });

        setPage({
          loading: false,
        });
      } catch (err) {
        setPage({
          loading: false,
        });
        console.log(err);
      }
    };
    getAll();
  }, []);
  if (page.loading) return <Loader />;
  return (
    <div className="container">
      <div className="row">{state.data}</div>
    </div>
  );
};

export default AboutTileMenu;
