import http from './httpService';

const API_ENDPOINT = '/api/folders';
const API_ENDPOINT_FILES = '/api/files';

const folders = async () => {
  try {
    const { data } = await http.get(API_ENDPOINT);

    return Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const folder = async (id) => {
  try {
    const { data } = await http.get(API_ENDPOINT + '/' + id);
    return Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const files = async (id) => {
  try {
    const { data } = await http.get(API_ENDPOINT_FILES + '/' + id);
    return data;
  } catch (err) {
    return Promise.reject(err);
  }
};

const downloadFile = async (id, preview = false) => {
  try {
    let url = API_ENDPOINT_FILES + '/' + id + '/download';

    if (preview) {
      url += '?preview=true';
    }

    const { data } = await http.resources({
      url: url,
      method: 'GET',
      responseType: 'blob',
    });
    return Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const downloadFolder = async (id) => {
  try {
    let url = API_ENDPOINT + '/' + id + '/download';

    const { data } = await http.resources({
      url: url,
      method: 'GET',
      responseType: 'arraybuffer',
    });
    return Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export default {
  all: folders(),
  folder,
  files,
  downloadFile,
  downloadFolder,
};
