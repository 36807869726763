import React, { useEffect, useReducer, useState } from 'react';
import { Title } from '../../components';
import { pages } from '../../services';

import { categoryUtil as categories, ComponentWithHTML } from '../../utilities';
import pageReducer, { PAGE_FETCH } from '../../reducer/pages';
import reducer, { ON_FETCH } from '../../reducer/folders';
import { Loader } from '../../ui';
import { TileMenu, NoData } from '../../components';

const Nodata = () => {
  return (
    <div className="col-12 h2 text-left">
      <NoData>No data available.</NoData>
    </div>
  );
};

const Home = (props) => {
  const [state, setState] = useReducer(reducer, []);
  const [pageState, setPageState] = useReducer(pageReducer, []);
  const [page, setPage] = useState({
    loading: true,
  });

  useEffect(() => {
    const getAll = async () => {
      try {
        const data = await categories;

        data.sort((a, b) => {
          return a.attr.order - b.attr.order;
        });

        setState({
          type: ON_FETCH,
          payload: data.map((category, idx) => <TileMenu data={category} key={idx} />),
        });

        setPage({
          loading: false,
        });
      } catch (err) {
        setPage({
          loading: false,
        });
        console.log(err);
      }
    };
    const fetchHomepage = async () => {
      try {
        const data = await pages.show('homepage');
        setPageState({
          type: PAGE_FETCH,
          payload: data,
        });
      } catch (err) {
        console.log(err);
      }
    };

    fetchHomepage();
    getAll();
    document.title = 'Pioneer Assets';
  }, [props.location]);

  if (page.loading) return <Loader />;
  return (
    <>
      <Title>Car Electronics Division</Title>
      <div className="row">
        {pageState.data ? (
          <div className="col-12">
            <ComponentWithHTML component="div" html={pageState.data.content || ''} />
          </div>
        ) : (
          <Nodata />
        )}
      </div>
      <div className="row text-center">{state.data || <Nodata />}</div>
    </>
  );
};

export default Home;
