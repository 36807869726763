import React, { useReducer } from 'react';
import queryString from 'query-string';
import { Title } from '../../components';
import { Input, Button } from '../../ui';
import { validation } from '../../utilities';
import { formReducer as reducer, SETTOAST, ONCHANGE, ONVALIDATE } from '../../reducer/forms';

import { auth } from '../../services';

const initFormErrors = {
  status: false,
  message: '',
};
const initForms = {
  forms: {
    password: {
      value: '',
      errors: initFormErrors,
      label: 'Password',
      type: 'password',
      required: true,
    },
    confirm_password: {
      value: '',
      errors: initFormErrors,
      label: 'Confirm Password',
      type: 'password',
      required: true,
    },
  },
};

const initState = {
  ...initForms,
  toast: {
    status: '',
    message: '',
  },
};

const formSchema = {
  password: {
    type: 'required',
    min: 6,
  },
  confirm_password: {
    type: 'confirm',
    confirm: 'password',
    title: 'Password',
  },
};

export default props => {
  const parsed = queryString.parse(props.location.search);
  const [state, dispatch] = useReducer(reducer, initState);

  const handleInputChange = ({ currentTarget: input }) => {
    let inputValue = input.value;

    if (input.type === 'checkbox') {
      inputValue = input.checked ? inputValue : '';
    }

    dispatch({
      type: ONCHANGE,
      payload: {
        name: input.name,
        value: inputValue,
        errors: initFormErrors,
      },
    });
  };

  const doValidation = () => {
    const validate = validation(formSchema, state.forms);
    let fieldErrors = {};

    if (validate.length > 0) {
      validate.map(v => {
        fieldErrors = {
          ...fieldErrors,
          [v.field]: {
            ...state.forms[v.field],
            errors: {
              status: true,
              message: v.message,
            },
          },
        };
      });

      dispatch({
        type: ONVALIDATE,
        payload: {
          errors: fieldErrors,
        },
      });

      return {
        error: true,
      };
    }

    if (!parsed.email) {
      dispatch({
        type: SETTOAST,
        payload: {
          status: 'danger',
          message: 'Email required!',
        },
      });

      return {
        error: true,
      };
    } else if (!parsed.token) {
      dispatch({
        type: SETTOAST,
        payload: {
          status: 'danger',
          message: 'Token required!',
        },
      });

      return {
        error: true,
      };
    }

    return {
      error: false,
    };
  };
  const handleSubmit = async e => {
    e.preventDefault();

    const validate = doValidation();
    if (validate.error) {
      return;
    }

    try {
      await auth.resetPassword({
        token: parsed.token,
        email: parsed.email,
        password: state.forms.password.value,
        password_confirmation: state.forms.confirm_password.value,
      });
      setTimeout(() => props.history.push('/'), 3000);
      dispatch({
        type: SETTOAST,
        forms: initForms.forms,
        payload: {
          status: 'success',
          message: 'Your password has been reset.',
        },
      });
    } catch (err) {
      dispatch({
        type: SETTOAST,
        payload: {
          status: 'danger',
          message: err.message,
        },
      });
    }
  };

  return (
    <>
      <Title>Reset Password</Title>
      <p className="mb-4">Enter your new password below.</p>
      <div className="row">
        <div className="col-12 col-md-6 col-lg-4">
          {state.toast.status !== '' && (
            <div className="row mb-4">
              <div className="col-12">
                <div className={`alert alert-${state.toast.status}`}>{state.toast.message}</div>
              </div>
            </div>
          )}
          <form action="" onSubmit={handleSubmit}>
            <div className="form-row">
              {Object.keys(state.forms).length > 0 &&
                Object.keys(state.forms).map(k => {
                  if (
                    state.forms[k].type === 'text' ||
                    state.forms[k].type === 'password' ||
                    state.forms[k].type === 'email'
                  ) {
                    return (
                      <Input
                        key={k}
                        type={state.forms[k].type}
                        name={k}
                        hidden={state.forms[k].hidden}
                        label={state.forms[k].label}
                        onChange={handleInputChange}
                        required={state.forms[k].required}
                        errors={state.forms[k].errors}
                        value={state.forms[k].value}
                        groupClassName={state.forms[k].col}
                      />
                    );
                  }
                })}{' '}
            </div>
            <div className="form-row">
              <div className="col-6">
                <Button type="submit" className="text-uppercase px-4">
                  Reset
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
