import http, { getCurrentUser } from './httpService';
import { errorMessage } from '../utilities';

const API_ENDPOINT = http.apiEndpoint;
const TOKEN_KEY = http.tokenKey;

export const login = async (params) => {
  try {
    const { data } = await http.post(API_ENDPOINT + '/login', {
      email: params.email,
      password: params.password,
    });
    loginWithJwt(data.access_token);

    window.location = params.redirect ? params.redirect : '/';
  } catch (err) {
    console.log('Login service error:', err.message);
    let message = "We're sorry, can not submit your request.";
    if (err.response) {
      message = err.response.status === 401 ? err.response.data.error : 'Unexpected error';
    }

    throw new Error(message);
  }
};
export const register = async (params) => {
  try {
    await http.post(API_ENDPOINT + '/registration', { ...params });
  } catch (err) {
    console.log('Register service error:', err.message);
    let message = "We're sorry, can not submit your request.";
    if (err.response) {
      message = errorMessage(err, message);
    }

    throw new Error(message);
  }
};

export const forgotPassword = async (params) => {
  try {
    await http.post(API_ENDPOINT + '/forgot-password', {
      email: params.email,
    });
  } catch (err) {
    console.log('Forgot password service error:', err.message);
    let message = "We're sorry, can not submit your request.";
    if (err.response) {
      message = errorMessage(err, message);
    }

    throw new Error(message);
  }
};
export const resetPassword = async (params) => {
  try {
    await http.post(API_ENDPOINT + '/reset-password', {
      token: params.token,
      email: params.email,
      password: params.password,
      password_confirmation: params.password_confirmation,
    });
  } catch (err) {
    console.log('Reset password service error:', err.message);
    let message = "We're sorry, can not submit your request.";
    if (err.response) {
      message = errorMessage(err, message);
    }

    throw new Error(message);
  }
};

export const logout = async (redirect) => {
  await http.get(API_ENDPOINT + '/logout');
  http.logout();
  window.location = redirect ? redirect : '/';
};

export const getJwt = () => {
  return localStorage.getItem(TOKEN_KEY);
};

export const loginWithJwt = (jwt) => {
  localStorage.setItem(TOKEN_KEY, jwt);
};

export const user = () => {
  return http.currentUser();
};

export const expiredToken = (props) => {
  let current_time = new Date().getTime() / 1000;
  const jwt = getCurrentUser();

  if (jwt) {
    if (current_time > jwt.exp) {
      props.history.push('/login');
      return;
    }
    return;
  }
  props.history.push('/login');
  return;
};

export default {
  login,
  register,
  forgotPassword,
  resetPassword,
  logout,
  loginWithJwt,
  getJwt,
  user: user(),
  expiredToken,
};
