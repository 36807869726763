import http from './httpService';

const API_ENDPOINT = '/api/pages';

const singlePage = async (slug) => {
  try {
    const { data } = await http.get(API_ENDPOINT + '/' + slug);

    return Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export default { show: singlePage };
