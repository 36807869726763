import React, { useState, useEffect } from 'react';

const Context = React.createContext();

export const Provider = props => {
  const injectModalOpen = open => {
    if (open) document.body.className = 'modal-open';
    if (!open) document.body.className = '';
  };
  const [state, setState] = useState({
    mobileMenuOpen: false,
    modalOpen: false,
    handleMobileMenu: action => {
      injectModalOpen(action);
      setState({
        ...state,
        mobileMenuOpen: action,
      });
    },
    handleModal: action => {
      injectModalOpen(action);
      setState({
        ...state,
        modalOpen: action,
      });
    },
  });

  useEffect(() => {
    setState({
      ...state,
      mobileMenuOpen: false,
    });

    injectModalOpen(false);
  }, [props.location]);

  return <Context.Provider value={state}>{props.children}</Context.Provider>;
};

export const Consumer = Context.Consumer;
