import React from 'react';
import Loader from './Loader';

export const Button = ({ className, type, color, onClick, children, outline, onLoading, hasLoader, disabled }) => {
  const colorAndType = outline ? `btn-outline-${color} btn-outline` : `btn-${color}`;
  const classHasLoader = hasLoader ? ' btn-with-loader' : '';
  const onLoadingClass = onLoading ? ' onloading' : '';

  return (
    <button
      type={type}
      className={`btn ${colorAndType} ${className}${classHasLoader}${onLoadingClass}`}
      onClick={onClick}
      disabled={disabled}
    >
      {(hasLoader && onLoading && <Loader size="small" color="white" />) || ''}
      {children}
    </button>
  );
};

Button.defaultProps = {
  type: 'button',
  className: '',
  color: 'primary',
  onClick: () => {},
  children: 'Submit',
  outline: false,
  onLoading: false,
  hasLoader: false,
  disabled: false,
};

export default Button;
