import React, { useState, useReducer, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { Title } from '../../components';
import { Input, Button, Loader } from '../../ui';
import { validation } from '../../utilities';
import { formReducer as reducer, ONCHANGE, ONVALIDATE, SETTOAST, ONFILLING } from '../../reducer/forms';
import { initFormErrors, initForms, formSchema } from './schema';

import { updateProfile, editProfile } from '../../services';
import { expiredToken } from '../../services/auth';

const initState = {
  ...initForms,
  toast: {
    status: '',
    message: '',
  },
};

const initPage = {
  title: 'Edit Profile',
  loading: true,
  data: '',
  formLoading: false,
  thankYou: false,
};

export default props => {
  const [state, dispatch] = useReducer(reducer, initState);
  const [page, setPage] = useState(initPage);

  useEffect(() => {
    expiredToken();
    document.title = 'Pioneer Assets | Edit Profile';

    (async () => {
      const data = await editProfile();
      dispatch({
        type: ONFILLING,
        payload: data,
      });
      setPage({
        ...page,
        loading: false,
      });
    })();
    return () => {
      document.title = 'Pioneer Assets';
    };
  }, [props.location]);

  const handleInputChange = ({ currentTarget: input }) => {
    let inputValue = input.value;

    if (input.type === 'checkbox') {
      inputValue = input.checked ? inputValue : '';
    }

    dispatch({
      type: ONCHANGE,
      payload: {
        name: input.name,
        value: inputValue,
        errors: initFormErrors,
      },
    });
  };

  const doValidation = () => {
    const validate = validation(formSchema, state.forms);
    let fieldErrors = {};

    if (validate.length > 0) {
      validate.map(v => {
        fieldErrors = {
          ...fieldErrors,
          [v.field]: {
            ...state.forms[v.field],
            errors: {
              status: true,
              message: v.message,
            },
          },
        };
      });

      dispatch({
        type: ONVALIDATE,
        payload: {
          errors: fieldErrors,
        },
      });

      return {
        error: true,
      };
    }

    return {
      error: false,
    };
  };
  const handleSubmit = async e => {
    e.preventDefault();

    const validate = doValidation();
    if (validate.error) {
      return;
    }

    setPage({
      ...page,
      formLoading: true,
    });

    try {
      await updateProfile({
        first_name: state.forms.first_name.value,
        last_name: state.forms.last_name.value,
        company_name: state.forms.company_name.value,
        email: state.forms.email.value,
        city: state.forms.city.value,
        state: state.forms.state.value,
        sales_rep_name: state.forms.sales_rep_name.value,
        zipcode: state.forms.zipcode.value,
      });
      dispatch({
        type: SETTOAST,
        payload: {
          status: 'success',
          message: 'Your profile has been updated.',
        },
      });
      // await doLogin();
      setPage({
        ...page,
        formLoading: false,
        // thankYou: true,
      });
      window.scrollTo(0, 0);
    } catch (err) {
      dispatch({
        type: SETTOAST,
        payload: {
          status: 'danger',
          message: err.message,
        },
      });
      setPage({
        ...page,
        formLoading: false,
      });
      window.scrollTo(0, 0);
    }
  };

  if (page.loading) return <Loader />;

  return (
    <>
      <Title>{page.title}</Title>
      <div className="row">
        <div className="col-12 col-md-8">
          {state.toast.status !== '' && (
            <div className="row mb-4">
              <div className="col-12">
                <div className={`alert alert-${state.toast.status}`}>{state.toast.message}</div>
              </div>
            </div>
          )}
          <form action="" onSubmit={handleSubmit}>
            <div className="form-row">
              {Object.keys(state.forms).length > 0 &&
                Object.keys(state.forms).map(k => {
                  if (state.forms[k].type === 'text' || state.forms[k].type === 'email') {
                    return (
                      <Input
                        key={k}
                        type={state.forms[k].type}
                        name={k}
                        label={state.forms[k].label}
                        onChange={handleInputChange}
                        errors={state.forms[k].errors}
                        value={state.forms[k].value}
                        groupClassName={state.forms[k].col}
                      />
                    );
                  }
                })}
            </div>
            <div className="form-row">
              <div className="col-6">
                <Link to="/" className="btn btn-outline-primary">
                  Cancel
                </Link>
              </div>
              <div className="col-6">
                <Button
                  type="submit"
                  className={`text-uppercase btn-block`}
                  hasLoader={true}
                  onLoading={page.formLoading}
                  disabled={page.formLoading}
                >
                  Save
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
