export const ONCHANGE = 'on_change';
export const ONVALIDATE = 'on_validate';
export const ONINIT = 'on_init';
export const SETTOAST = 'on_toast';
export const ONFILLING = 'on_filling';

export const formReducer = (state, action) => {
  switch (action.type) {
    case ONCHANGE:
      return {
        ...state,
        forms: {
          ...state.forms,
          [action.payload.name]: {
            ...state.forms[action.payload.name],
            value: action.payload.value,
            errors: action.payload.errors,
          },
        },
        toast: {
          status: '',
          message: '',
        },
      };
    case ONVALIDATE:
      return {
        ...state,
        forms: {
          ...state.forms,
          ...action.payload.errors,
        },
      };
    case SETTOAST:
      return {
        ...state,
        forms: (action.forms && { ...action.forms }) || {
          ...state.forms,
        },
        toast: {
          status: action.payload.status,
          message: action.payload.message,
        },
      };
    case ONINIT:
      return action.payload.state;
    case ONFILLING: {
      const { payload } = action;
      let formState = state.forms;
      Object.keys(payload).map((formName) => {
        if (formState[formName]) {
          formState[formName].value = payload[formName];
        }
      });
      return {
        ...state,
        forms: {
          ...formState,
        },
      };
    }
    default:
      return state;
  }
};
