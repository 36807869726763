import { useRef, useEffect, useState, useReducer } from 'react';
import reducer, { ON_FETCH } from '../reducer/folders';
import { dataMaping } from '../utilities/folderUtil';
import { search } from '../services';
import { getSearchKeywords } from '../utilities';
import { useLocation } from 'react-router-dom';

export default props => {
  const query = new URLSearchParams(useLocation().search);
  const [keywords, setKeywords] = useState(query.get('keyword'));

  const rendered = useRef(true);
  const [state, setState] = useReducer(reducer, []);
  const [page, setPage] = useState({
    loading: true,
    noData: false,
    viewType: 'folder',
  });
  const [isForbiden, setIsForbiden] = useState(false);
  const [sorts, setSorts] = useState({
    orderBy: 'folder_name',
    order: 'asc',
  });

  useEffect(() => {
    return () => {
      rendered.current = false;
    };
  }, []);

  useEffect(() => {
    const loadData = async () => {
      setPage({
        ...page,
        loading: true,
      });
      try {
        setKeywords(query.get('keyword'));

        if (rendered.current) {
          const data = await search.submitSearch({
            keyword: query.get('keyword').toLowerCase(),
          });

          setState({
            type: ON_FETCH,
            payload: dataMaping(data, props),
          });

          const findFiles = data.find(d => {
            return d.type === 'file';
          });

          if (findFiles) {
            setSorts({
              ...sorts,
              orderBy: 'file_name',
            });
          }

          setPage({
            ...page,
            viewType: findFiles ? 'file' : 'folder',
            loading: false,
          });
        }
      } catch (err) {
        console.log(err.message);

        if (err.response) {
          if (err.response.status === 403) {
            setIsForbiden(true);
          }
        }
        setPage({
          ...page,
          loading: false,
          noData: true,
        });
      }
    };

    loadData();
  }, [props.location.search]);

  return {
    sorts,
    state,
    page,
    isForbiden,
    keywords,
  };
};
