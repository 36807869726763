import React from 'react';

const Label = ({label: caption, required, name, labelClassName}) => {
    return (
        <label htmlFor={name} className={labelClassName}>
            {(typeof caption === 'function' && caption()) || caption}
            {required && <em className="text-danger">*</em>}
        </label>
    );
};

Label.defaultProps = {
    label: '',
    required: false,
    labelClassName: ''
};

export default Label;
