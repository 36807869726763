export const createHeading = (title, key, content, sorting = false, attr = {}) => {
  return { title, key, content, attr, sorting };
};

export const sortingData = (data, sort) => {
  const sortedData = data;
  sortedData.sort((a, b) => {
    if (a[sort.orderBy] && b[sort.orderBy]) {
      let orderByA = a[sort.orderBy].toLowerCase();
      let orderByB = b[sort.orderBy].toLowerCase();
      if (sort.order === 'desc') {
        if (orderByB < orderByA) {
          return -1;
        }
        if (orderByB < orderByA) {
          return 1;
        }

        // names must be equal
        return 0;
      }
      if (sort.order === 'asc') {
        if (orderByB > orderByA) {
          return -1;
        }
        if (orderByB < orderByA) {
          return 1;
        }

        // names must be equal
        return 0;
      }
    }
  });
  return sortedData;
};

export default {
  heading: createHeading,
  sortingData,
};
