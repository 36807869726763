import psdIcon from '../assets/psd.png';
import jpgIcon from '../assets/jpg.png';
import pdfIcon from '../assets/pdf.png';
import folderIco from '../assets/folder.png';
import aiIco from '../assets/ai.png';
import epsIco from '../assets/eps.png';

const fileIcon = type => {
  if (type === 'folder') {
    return folderIco;
  }
  if (type) {
    const lowerType = type.toLowerCase();
    if (lowerType.indexOf('.psd') > -1) {
      return psdIcon;
    } else if (lowerType.indexOf('.pdf') > -1) {
      return pdfIcon;
    } else if (lowerType.indexOf('.ai') > -1) {
      return aiIco;
    } else if (lowerType.indexOf('.eps') > -1) {
      return epsIco;
    }
  }
  return jpgIcon;
};

export const dataMaping = (data, props) => {
  const folders = [];
  const { pathname, search } = props.location;

  data.map(d => {
    const createAlias = convertToSlug(d.name);
    let path;

    if (!d.path) {
      path = pathname + '' + search + '&folder[]=' + d.id + '&folder_name[]=' + createAlias;
    } else {
      if (!d.category) {
        path = d.path + '&folder[]=' + d.id + '&folder_name[]=' + createAlias;
      } else {
        path = d.path;
      }
    }

    if (d.type === 'folder') {
      folders.push(createData(d.id, d.name, path, d.files || '0', path, d.total_count));
    } else {
      folders.push(createDataFile(d.id, d.name, '/api/files/' + d.id, '/api/files/' + d.id + '/download'));
    }
  });
  return folders;
};

export const createData = (id, folder_name, url, files, download_url, total) => {
  return { id, folder_name, url, files, download_url, total };
};

export const createDataFile = (id, file_name, preview_url, download_url) => {
  return { id, file_name, preview_url, download_url };
};

export const convertToSlug = text => {
  return text
    .toLowerCase()
    .replace(/[^\w ]+/g, '')
    .replace(/ +/g, '-');
};

export const getCurrentID = parsed => {
  if (parsed['folder[]']) {
    if (typeof parsed['folder[]'] === 'object') {
      return parsed['folder[]'][parsed['folder[]']['length'] - 1];
    } else {
      return parsed['folder[]'];
    }
  } else {
    return parsed.category;
  }
};

export const excludePreview = formatType => {
  if (
    formatType.indexOf('.jpg') > -1 ||
    formatType.indexOf('.png') > -1 ||
    formatType.indexOf('.gif') > -1 ||
    formatType.indexOf('.jpg') > -1
  ) {
    return false;
  }

  return true;
};

export default {
  dataMaping,
  createData,
  convertToSlug,
  getCurrentID,
  fileIcon,
  excludePreview,
};
