import React from 'react';
import { NavLink as Link } from 'react-router-dom';
import { auth } from '../../services';
import { Button } from '../../ui';

const handleLogout = async () => {
  await auth.logout();
};

const AuthNav = () => {
  return (
    <ul className="nav uppercase align-items-end mr-min-md-1 justify-content-end text-uppercase">
      {!auth.user && (
        <>
          <li className="nav-item">
            <Link className="nav-link auth-link" to="/login">
              Login
            </Link>
          </li>
          <li className="nav-item auth-link">
            <Link className="nav-link auth-link" to="/register">
              Register
            </Link>
          </li>
        </>
      )}
      {auth.user && (
        <>
          <li className="nav-item auth-link">
            <Link className="nav-link auth-link" to="/edit-profile">
              Profile
            </Link>
          </li>
          <li className="nav-item auth-link">
            <Link className="nav-link auth-link" to="/logout">
              Logout
            </Link>
          </li>
        </>
      )}
    </ul>
  );
};

export default AuthNav;
