export const PAGE_FETCH = 'page_fetch';
export const on_UPDATE = 'on_update';

export default (state, action) => {
  switch (action.type) {
    case PAGE_FETCH:
      return {
        ...state,
        data: action.payload,
      };

    default:
      return state;
  }
};
