import textCar from '../assets/cards/text-car.png';
import textMarine from '../assets/cards/text-marine.png';
import textRdrive from '../assets/cards/text-rdrive.png';
import textPromotionTools from '../assets/cards/text-promotion-tools.png';
import textHelp from '../assets/cards/text-help.png';
import folders from '../services/folders';
import folderUtil from './folderUtil';

export const getTopFolders = async () => {
  let topFolders = [];
  try {
    const apiResult = await folders.all;
    apiResult.forEach((folder) => {
      let attr = {};

      switch (folder.name) {
        case 'Car':
          attr = {
            wrapCol: false,
            padding: 'pr-md-0 pr-sm-2',
            margin: 'mb-3',
            textCard: textCar,
            order: 0,
          };
          break;

        case 'Marine':
          attr = {
            wrapCol: false,
            padding: '',
            margin: 'mb-3 pl-sm-2 px-md-3',
            textCard: textMarine,
            order: 1,
          };
          break;

        case 'rDrive':
          attr = {
            wrapCol: false,
            padding: 'pl-md-0 pr-md-3 pr-sm-2',
            margin: 'mb-3',
            textCard: textRdrive,
            order: 2,
          };
          break;

        case 'Promotional Tools':
          attr = {
            wrapCol: false,
            padding: 'pr-md-0 pl-sm-2 pl-md-3',
            margin: 'mb-3',
            textCard: textPromotionTools,
            order: 3,
          };
          break;

        case 'Help':
          attr = {
            wrapCol: true,
            padding: '',
            margin: 'mb-3',
            textCard: textHelp,
            order: 4,
          };
          break;

        default:
          attr = {
            wrapCol: false,
            padding: 'pr-md-0 pr-sm-2',
            margin: 'mb-3',
            order: 5,
          };
          break;
      }

      if (folder.image) {
        attr['backgroundImg'] = folder.image;
      }

      topFolders.push({
        ...folder,
        attr: attr,
        alias: folderUtil.convertToSlug(folder.name),
        // url: `/categories/${folder.id}/${folderUtil.convertToSlug(folder.name)}`,
        url: {
          pathname: '/categories',
          search: `?category=${folder.id}&category_name=${folderUtil.convertToSlug(folder.name)}`,
        },
      });
    });
  } catch (err) {
    return Promise.reject(err);
  }

  return topFolders;
};

const categoryUtil = getTopFolders();

export default categoryUtil;
