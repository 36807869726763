import { tableUtil } from '../utilities';
export const SORT_DATA = 'sort_data';

export default (state, action) => {
  switch (action.type) {
    case SORT_DATA: {
      const data = tableUtil.sortingData(action.payload, {
        orderBy: action.order.orderBy,
        order: action.order.order,
      });
      return {
        ...state,
        data,
      };
    }
    default:
      return state;
  }
};
