import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { getSearchKeywords } from '../../utilities';
import { useLocation } from 'react-router-dom';

const SearchForm = props => {
  const query = new URLSearchParams(useLocation().search);
  const [search, setSearch] = useState(query.get('keyword') ? query.get('keyword') : '');
  const handleChange = ({ currentTarget: input }) => {
    setSearch(input.value);
  };
  const handleSearch = e => {
    e.preventDefault();
    props.history.push(`/search?keyword=${search}`);
  };

  useEffect(() => {}, [props.location.search]);
  return (
    <form className="search-form form-inline justify-content-end" onSubmit={handleSearch}>
      <div className="form-group d-flex">
        <input
          type="text"
          className="form-control form-control-search "
          id="globalSearch"
          placeholder="Search"
          value={search}
          onChange={handleChange}
        />
        <button type="submit" className="btn btn-primary btn-search bg-image">
          &nbsp;
        </button>
      </div>
    </form>
  );
};

export default withRouter(SearchForm);
