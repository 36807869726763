import http from './httpService';
import { errorMessage } from '../utilities';

const API_ENDPOINT = '/api/search';

const submitSearch = async (params) => {
  try {
    const { data } = await http.get(API_ENDPOINT, { params: { ...params } });
    return Promise.resolve(data);
  } catch (err) {
    console.log('Search service error:', err.message);
    let message = "We're sorry, can not submit your request.";
    if (err.response) {
      message = errorMessage(err, message);
    }

    throw new Error(message);
  }
};

export default { submitSearch };
