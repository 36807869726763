import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { GuestRoute } from '../components';

import Home, {
  NotFound,
  Search,
  Contact,
  Login,
  Register,
  ForgetPassword,
  ResetPassword,
  LandingPage,
  Profile,
} from '../pages';

const RouterDefault = () => {
  return (
    <Switch>
      <Route path={`/`} exact render={(props) => <Home {...props} />} />
      <Route path={`/search`} exact render={(props) => <Search {...props} />} />
      <Route path={`/contact`} exact render={(props) => <Contact {...props} />} />
      <Route path={`/edit-profile`} exact render={(props) => <Profile {...props} />} />
      <GuestRoute path={`/login`} exact render={(props) => <Login {...props} />} />
      <GuestRoute path={`/register`} exact render={(props) => <Register {...props} />} />
      <GuestRoute path={`/forget-password`} exact render={(props) => <ForgetPassword {...props} />} />
      <GuestRoute path={`/reset-password`} exact render={(props) => <ResetPassword {...props} />} />
      <Route path={`/:slug`} exact render={(props) => <LandingPage {...props} />} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default RouterDefault;
