export const errorMessage = (err, defaultMsg = '') => {
  if (err.response) {
    switch (err.response.status) {
      case 401:
        return 'You have no permission to access this request';
      case 422:
        let errMsg = 'Incorrect request data';
        if (err.response.data) {
          return knownListedError(err.response.data, errMsg);
        }
        return errMsg;
      default:
        return defaultMsg !== '' ? defaultMsg : err.message;
    }
  } else {
    return defaultMsg;
  }
};

export const knownListedError = (resp, defaultMsg) => {
  let message = defaultMsg;
  let error = {};

  if (resp.error) {
    error = resp.error;
  } else if (resp.errors) {
    error = resp.errors;
  }

  Object.keys(error).map(field => {
    message = error[field][0];
  });

  return message;
};
