import React, { useEffect } from 'react';

import RouterDefault from '../router';
import { Header, Footer, MobileMenuModal, Modal } from '../components';
import { Loader } from '../ui';
import { lastPage } from '../utilities';

import { Consumer, Provider } from '../context';

const DefaultLayout = props => {
  const [state, setState] = React.useState({
    loading: false,
    pageClassName: '',
  });

  useEffect(() => {
    const { pathname } = props.location || {};

    const extract = (pathname && pathname.split('/')) || [];

    setState({
      loading: false,
      pageClassName: extract.length > 1 ? extract[1] : '',
    });
    lastPage.setLastPage(props.location);
    window.scrollTo(0, 0);
  }, [props.location]);

  return (
    <Provider {...props}>
      <Consumer>
        {value => {
          return (
            <div className={`page ${state.pageClassName}`}>
              <section className="section section-header">
                <Header />
              </section>
              <main className={`main container`}>{(!state.loading && <RouterDefault {...props} />) || <Loader />}</main>
              <section className="section section-footer">
                <Footer />
              </section>
              <MobileMenuModal open={value.mobileMenuOpen} />
              <Modal open={value.modalOpen} />
            </div>
          );
        }}
      </Consumer>
    </Provider>
  );
};

export default DefaultLayout;
