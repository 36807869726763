import React from 'react';
import Nav, { AuthNav } from '../Nav';

export default () => {
  return (
    <nav className="bg-primary navigation-header d-none d-md-block">
      <div className="container h-100">
        <div className="row h-100 align-items-end">
          <div className="col-8">
            <Nav />
          </div>
          <div className="col-4">
            <AuthNav />
          </div>
        </div>
      </div>
    </nav>
  );
};
